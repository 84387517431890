import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import RichText from "../components/richText/index"

import { SectionHeading } from "../components/headings/index"

const TermsOfUsePage = ({ location, data }) => {
  const { terms } = data

  return (
    <Layout path={location.pathname}>
      <Seo title="Splošni Pogoji" lang="sl" />
      <section className="max-w-screen-md mx-auto px-4 py-10 sm:py-16">
        <SectionHeading title="terms" />
        <RichText content={terms.edges[0].node.pogojiPoslovanja} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query termsQuerySl {
    terms: allContentfulTermsAndPrivacy(filter: { node_locale: { eq: "sl" } }) {
      edges {
        node {
          pogojiPoslovanja {
            raw
          }
        }
      }
    }
  }
`

export default TermsOfUsePage
