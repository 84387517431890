import React from "react"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Bold = ({ children }) => <span className="font-bold">{children}</span>
const Text = ({ children }) => <p className="mb-4 mt-3 text-base">{children}</p>
const UnorderedList = ({ children }) => (
  <ul className="list-disc block ml-8 text-left">{children}</ul>
)
const H4 = ({ children }) => (
  <h4 className="text-xl font-semibold mb-3 uppercase">{children}</h4>
)
const H3 = ({ children }) => (
  <h3 className="font-semibold text-lg mb-3 uppercase">{children}</h3>
)

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
  },
}

const RichText = ({ content }) => {
  return <div>{renderRichText(content, options)}</div>
}

export default RichText
